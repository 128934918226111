import { useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import DataLabelComponent from "../../../components/generic/DataLabel";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../shared/Utils";
import { LanguageCodeDescription } from "../../../models";

interface PatientInformationProps {

}

const PatientInformationComponent = (props: PatientInformationProps) => {
    const navigate = useNavigate();
    const {
        patientBasicInformation: patient,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const practice = (patient?.screenerUserPractices && patient?.screenerUserPractices?.length > 0) ? patient?.screenerUserPractices[0]?.providerPractice : undefined;
    const provider = (practice?.providers && practice?.providers?.length > 0) ? practice?.providers[0] : undefined;
    const location = (patient?.userScreenerSessions && patient?.userScreenerSessions?.length > 0) ? patient?.userScreenerSessions[0]?.location : undefined;
    const physician = provider?.firstName + " " + provider?.lastName;

    const handleEvent = useCallback(() => {
        navigate(`${RoutePaths.careCoordinator.potentialPatients.root}?page=1`)
    }, [navigate]);

    useEffect(() => {
        window.addEventListener("popstate", handleEvent);
        return () => window.removeEventListener("popstate", handleEvent);
    });

    return (
        <>
            <div className="patient-information-wrapper">
                <div className="patient-information-card">
                    <div className="patient-information-section">
                        <div className="patient-information-section-title">Patient Information</div>
                        <div className="patient-information-section-content">
                            <div className="grid grid-cols-3 gap-6">
                                <DataLabelComponent label={"Date of Birth"}>
                                    {patient?.dob ? DateTime.fromISO(patient?.dob?.toString(), { zone: "utc" }).toFormat('MM/dd/yyyy') : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Gender"}>
                                    {patient?.gender ? <span className="capitalize"> {patient?.gender} </span> : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Screening Date"}>
                                    {patient?.screeningDate ? DateTime.fromISO(patient?.screeningDate?.toString(), { zone: "utc" }).toFormat('MM/dd/yyyy') : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Email ID"}>
                                    {patient?.email || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Phone Number"}>
                                    {patient?.contactPhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Patient Alternate Number"}>
                                    {patient?.alternatePhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Preferred Language"}>
                                    {patient?.preferredLanguage && LanguageCodeDescription[patient?.preferredLanguage] || "English"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Emergency Contact Name">
                                    {
                                        patient?.guardianFirstName || patient?.guardianLastName
                                            ? `${patient?.guardianFirstName} ${patient?.guardianLastName}`
                                            : "N/A"
                                    }
                                </DataLabelComponent>
                                <DataLabelComponent label="Relation">
                                    {patient?.relationshipWithPatient || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Emergency Contact Number">
                                    {patient?.guardianPhoneNumber || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Zip Code"}>
                                    {patient?.zipCode || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Address"}>
                                    <div className="break-all overflow-hidden overflow-ellipsis whitespace-pre-wrap">
                                        {patient?.address || "N/A"}
                                    </div>
                                </DataLabelComponent>
                            </div>
                        </div>
                    </div>
                    <div className="patient-information-section">
                        <div className="patient-information-section-title">Physician Information</div>
                        <div className="patient-information-section-content">
                            <div className="grid grid-cols-3 gap-6">
                                <DataLabelComponent label={"Practice"}>
                                    {practice?.name}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Screened Location"}>
                                    {location?.name || ""} , {location?.addressLine || ""} {location?.city || ""} {location?.state || ""}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Physician"}>
                                    {physician || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Physician NPI"}>
                                    {provider?.npi || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Tax ID"}>
                                    {practice?.taxId || "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label={"Medicaid ID"}>
                                    {provider?.medicaidId || "N/A"}
                                </DataLabelComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PatientInformationComponent;
